import React from "react"

import "../css/pages/devis.scss"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"

import Contact from "../containers/index/contact"

const DevisPage = () => {
    return (
        <Layout className={"quotation"}>
            <SEO title="Devis gratuit maçonnerie, menuiserie, isolation" />
            <Contact/>
        </Layout>
    )
}

export default DevisPage
